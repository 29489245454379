<template>
    <div class="index">
        <div class="ls_flex">
            <a v-for="item in menu" :key="item.name" :target="item.isOut ? '_blank' : ''" :href="item.route">{{ item.name
            }}</a>
        </div>

        <!-- <div class="img_list">
            <img src="@/assets/imgs/houtai_zuopshuju.png" />
            <img src="@/assets/imgs/houtai_yonghushuju.png" />
            <img src="@/assets/imgs/houtai_kejianshuju.png" />
            <img src="@/assets/imgs/houtai_bisaishuju.png" />
        </div> -->
        <div class="chart_list" v-if="isTeacher">
            <!-- 作品数据 -->
            <div id="zpsj"></div>
            <!-- 用户数据 -->
            <div id="yhsj"></div>
            <!-- 比赛数据 -->
            <div id="bssj"></div>
            <!-- 课件数据 -->
            <div id="kjsj"></div>
        </div>
        <div v-else class="tab_list">
            <div class="tab_item" @click="$router.push({ path: '/homework' })">
                <div class="ls_flex_between">
                    <div class="title">插花作业</div>
                    <div>还有{{ stuObj.schoolworkCount }}份作业</div>
                </div>
                <div class="list-box" v-if="stuObj.schoolworkList.length">
                    <div class="list-item" v-for="(item, index) in stuObj.schoolworkList" :key="index">
                        <div>{{ item.name }}</div>
                        <div>要求时间：{{ item.time }}</div>
                    </div>
                </div>
                <div class="no-data" v-else>暂时没有未完成的作业哦~</div>
            </div>
            <div class="tab_item" @click="$router.push({ path: '/race' })">
                <div class="title">插花比赛</div>
                <div class="list-box" v-if="stuObj.competitionList.length">
                    <div class="list-item" v-for="(item, index) in stuObj.competitionList" :key="index">
                        <div>{{ item.name }}</div>
                        <div>开始时间：{{ item.time }}</div>
                    </div>
                </div>
                <div class="no-data" v-else>暂时没有您可以参加的比赛哦~</div>
            </div>
            <div class="tab_item" @click="$router.push({ path: '/courseware' })">
                <div class="title">教学课件</div>
                <div class="list-box" v-if="stuObj.coursewareOrganizationList.lnegth">
                    <div class="list-item" v-for="(item, index) in stuObj.coursewareOrganizationList" :key="index">
                        <div>{{ item.name }}</div>
                        <div>发布时间：{{ item.time }}</div>
                    </div>
                </div>
                <div class="no-data" v-else>暂时没有课件哦~</div>
            </div>
            <div class="tab_item" v-if="isTeacher" @click="$router.push({ path: '/courseware' })">
                <div class="title">平台课件</div>
                <div class="list-box" v-if="stuObj.coursewarePlatformList.length">
                    <div class="list-item" v-for="(item, index) in stuObj.coursewarePlatformList" :key="index">
                        <div>{{ item.name }}</div>
                        <div>发布时间：{{ item.time }}</div>
                    </div>
                </div>
                <div class="no-data" v-else>暂时没有课件哦~</div>
            </div>
        </div>
    </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    BarChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer
]);
// import * as echarts from 'echarts';

export default {
    data () {
        return {
            menu: [
                {
                    name: "插花练习",
                    route: this.$editAddr,
                    isOut: true,
                },
                {
                    name: "插花比赛",
                    route: "/#/race",
                },
                {
                    name: "教学课件",
                    route: "/#/courseware",
                },
                {
                    name: "插花作业",
                    route: "/#/homework",
                },
            ],
            stuObj: {
                schoolworkList: [], //作业列表
                schoolworkCount: 0, // 作业数量
                competitionList: [], // 比赛列表
                coursewareOrganizationList: [], // 学校课件列表
                coursewarePlatformList: [], // 平台课件列表
            }
        };
    },
    mounted () {
        if (this.isTeacher) {
            this.getChart("/report/product", "zpsj", "作品数据", "bar")
            this.getChart("/report/user", "yhsj", "用户数据", "line")
            this.getChart("/report/competition", "bssj", "比赛数据", "bar")
            this.getChart("/report/courseware", "kjsj", "课件数据", "bar")
        } else {
            this.getSutDetail();
        }

    },
    methods: {
        getSutDetail () {
            this.$ajax.get("/studentHome/queryAllList").then(res => {
                this.stuObj = res.data
            })
        },
        getChart (url, id, title, type = "bar") {
            this.$ajax.post(url, {}).then(res => {

                var domChart = echarts.init(document.getElementById(id));
                let series = [];
                for (let i = 0; i < res.data.dataset[0].source.length - 1; i++) {
                    series.push({ type, seriesLayoutBy: 'row' })
                }

                let option = {
                    title: {
                        text: title
                    },
                    legend: {
                        // selectedMode: 'single', 选中某一项
                        top: 10,
                        right: 50
                    },
                    tooltip: { trigger: 'item' },
                    xAxis: { type: 'category' },
                    yAxis: {},
                    dataset: res.data.dataset,
                    series: series,
                };
                domChart.setOption(option);
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    padding: 20px 20px 0 0;

    a {
        flex: 1;
        padding: 35px 0;
        border-radius: 5px;
        border: 1px solid #8bb229;
        text-align: center;
        font-size: 22px;
        line-height: 30px;
        color: #fff;
        background: #8bb229;
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);

        &:hover {
            background: #9bc434;
            color: #fff;
        }

        +a {
            margin-left: 35px;
        }
    }
}

.chart_list {
    margin-top: 33px;
    width: 1114px;
    margin: 33px auto 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div {
        width: 500px;
        height: 300px;
    }
}

.tab_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;

    .tab_item {
        box-sizing: border-box;
        width: 49%;
        height: 300px;
        border: 1px solid #cccccc;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 20px 30px;
        background-color: #fff;
        cursor: pointer;
    }

    .list-box {
        height: 100%;
        overflow: scroll;
        /* firefox */
        scrollbar-width: none;
        /* IE 10+ */
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;


            line-height: 50px;
            height: 50px;
            border-bottom: 1px solid #afafaf;
        }
    }

    .no-data {
        text-align: center;
        line-height: 200px;
    }

}

.img_list {
    margin-top: 33px;
    overflow: hidden;
    width: 1114px;
    margin: 33px auto 0 auto;

    >img {
        float: left;
        width: 540px;
        margin-bottom: 33px;

        &:nth-child(2n + 1) {
            margin-right: 33px;
        }
    }
}
</style>